/**
 * Component: Sidebar
 * ------------------
 */

.main-sidebar {
  z-index: $zindex-main-sidebar;
  height: 100vh;
  overflow-y: hidden;
}

.sidebar {
  padding-bottom: $sidebar-padding-y;
  padding-top: $sidebar-padding-y;
  padding-left: $sidebar-padding-x;
  padding-right: $sidebar-padding-x;
  overflow-y: auto;
  height: calc(100% - 4rem);
}

// Sidebar user panel
.user-panel {
  position: relative;

  [class*="sidebar-dark"] & {
    border-bottom: 1px solid lighten($dark, 12%);
  }

  [class*="sidebar-light"] & {
    border-bottom: 1px solid $gray-300;
  }

  &,
  .info {
    overflow: hidden;
    white-space: nowrap;
  }

  .image {
    padding-left: $nav-link-padding-x - .2;
    display: inline-block;
  }

  img {
    width: $sidebar-user-image-width;
    height: auto;
  }

  .info {
    display: inline-block;
    padding: 5px 5px 5px 10px;
  }

  .status,
  .dropdown-menu {
    font-size: $font-size-sm;
  }
}

// Sidebar navigation menu
.nav-sidebar {
  // All levels
  .nav-item {
    > .nav-link {
      margin-bottom: 0.2rem;
      .right {
        @include transition(transform $transition-fn $transition-speed);
      }
    }
  }

  .nav-link > p > .right {
    position: absolute;
    right: 1rem;
    top: 12px;

    &:nth-child(2) {
      right: 2.5rem;
    }
  }

  .menu-open {
    > .nav-treeview {
      display: block;
    }

    > .nav-link {
      i.right {
        @include rotate(-90deg);
      }
    }
  }

  // First Level
  > .nav-item {
    margin-bottom: 0;

    .nav-icon {
      text-align: center;
      width: $sidebar-nav-icon-width;
      font-size: 1.2rem;
      margin-right: .2rem;
      
      &.fa,
      &.fas,
      &.far,
      &.fab {
        font-size: 1.1rem;
      }
    }

    .float-right {
      margin-top: 3px;
    }
  }

  // Tree view menu
  .nav-treeview {
    display: none;
    list-style: none;
    padding: 0;

    > .nav-item {
      > .nav-link {
        > .nav-icon {
          width: $sidebar-nav-icon-width;
        }
      }
    }
  }

  &.nav-child-indent { 
    .nav-treeview{
      padding-left: 1rem;
    }
  }

  .nav-header {
    font-size: .9rem;
    padding: $nav-link-padding-y;

    &:not(:first-of-type) {
      padding: 1.7rem 1rem .5rem 1rem;
    }
  }

  .nav-link p {
    display: inline-block;
    margin: 0;
  }
}

#sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: $zindex-main-sidebar - 1;

  @include media-breakpoint-down(md) {
    .sidebar-open & {
      display: block;
    }
  }
}

.sidebar-mini-md.sidebar-open {
  #sidebar-overlay {
    @include media-breakpoint-up(sm) {
      display: none;
    }
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}


// Sidebar variants
@each $name, $color in $theme-colors {
  .sidebar-dark-#{$name} {
    @include skin-dark-sidebar($color)
  }

  .sidebar-light-#{$name} {
    @include skin-light-sidebar($color)
  }
}



/*
 * Page: Login & Register
 * ----------------------
 */

.login-logo,
.register-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
  a {
    color: #444;
  }
}

.login-page,
.register-page {
  background: $gray-200;
}

.login-box,
.register-box {
  width: 360px;
  margin: 7% auto;
  @media (max-width: map-get($grid-breakpoints, sm)) {
    width: 90%;
    margin-top: 20px;
  }
}

.login-card-body,
.register-card-body {
  background: $white;
  padding: 20px;
  border-top: 0;
  color: #666;
  .input-group {
    .form-control {
      border-right: none;

      &:focus {
        box-shadow: none;
        & ~ .input-group-append .input-group-text {
          border-color: $input-focus-border-color;
        }
      }
      
      &.is-valid {
        &:focus {
          box-shadow: none;
        }
        & ~ .input-group-append .input-group-text {
          border-color: $success;
        }
      }

      &.is-invalid {
        &:focus {
          box-shadow: none;
        }
        & ~ .input-group-append .input-group-text {
          border-color: $danger;
        }
      }
    }
    .input-group-text {
      color: #777;
      background-color: transparent;
      border-left: none;
      transition: $input-transition;
      // Fix boostrap issue temporarily https://github.com/twbs/bootstrap/issues/25110
      border-bottom-right-radius: $border-radius !important;
      border-top-right-radius: $border-radius !important;
    }
  }
}

.login-box-msg,
.register-box-msg {
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px;
}

.social-auth-links {
  margin: 10px 0;
}

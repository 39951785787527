/*
 * Component: Dropdown menus
 * -------------------------
 */

// General Dropdown Rules
//.dropdown-item {
//  &:first-of-type {
//    @include border-top-radius($border-radius);
//  }
//  &:last-of-type {
//    @include border-bottom-radius($border-radius);
//  }
//}

.dropdown-item-title {
  font-size: $font-size-base;
  margin: 0;
}

// Dropdown Sizes
.dropdown-menu-lg {
  min-width: 280px;
  max-width: 300px;
  padding: 0;
  .dropdown-divider {
    margin: 0;
  }
  .dropdown-item {
    padding: $dropdown-padding-y $dropdown-item-padding-x;
  }
  p {
    white-space: normal;
    margin: 0;
  }
}

// Dropdown header and footer
.dropdown-footer,
.dropdown-header {
  text-align: center;
  display: block;
  padding: .5rem $dropdown-item-padding-x;
  font-size: $font-size-sm;
}

/* Add fade animation to dropdown menus by appending
 the class .animated-dropdown-menu to the .dropdown-menu ul (or ol)*/
.open:not(.dropup) > .animated-dropdown-menu {
  backface-visibility: visible !important;
  @include animation(flipInX .7s both);
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  100% {
    transform: perspective(400px);
  }
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  100% {
    -webkit-transform: perspective(400px);
  }
}

/* Fix dropdown menu in navbars */
.navbar-custom-menu > .navbar-nav {
  > li {
    position: relative;
    > .dropdown-menu {
      position: absolute;
      right: 0;
      left: auto;
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar-custom-menu > .navbar-nav {
    float: right;
    > li {
      position: static;
      > .dropdown-menu {
        position: absolute;
        right: 5%;
        left: auto;
        border: 1px solid #ddd;
        background: $white;
      }
    }
  }
}

/* User Menu */
.navbar-nav > .user-menu {
  > .nav-link:after {
    content:none;
  }
  > .dropdown-menu {
    @include border-top-radius(0);
    padding: 0;
    border-top-width: 0;
    width: 280px;

    &,
    > .user-body {
      @include border-bottom-radius(4px);
    }
    
    // Header menu
    > li.user-header {
      height: 175px;
      padding: 10px;
      text-align: center;
      // User image
      > img {
        z-index: 5;
        height: 90px;
        width: 90px;
        border: 3px solid;
        border-color: transparent;
        border-color: rgba(255, 255, 255, 0.2);
      }
      > p {
        z-index: 5;
        font-size: 17px;
        //text-shadow: 2px 2px 3px #333333;
        margin-top: 10px;
        > small {
          display: block;
          font-size: 12px;
        }
      }
    }

    // Menu Body
    > .user-body {
      padding: 15px;
      border-bottom: 1px solid #f4f4f4;
      border-top: 1px solid #dddddd;
      @include clearfix();
      a {
        color: #444 !important;
        @include media-breakpoint-up(sm) {
          background: #fff !important;
          color: #444 !important;
        }
      }
    }

    // Menu Footer
    > .user-footer {
      background-color: #f9f9f9;
      padding: 10px;
      @include clearfix();
      .btn-default {
        color: #666666;
        &:hover {
          @include media-breakpoint-up(sm) {
            background-color: #f9f9f9;
          }
        }
      }
    }
  }
  .user-image {
    float: left;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: -2px;
    @include media-breakpoint-up(sm) {
      float: none;
      margin-right: 0;
      margin-top: -8px;
      line-height: 10px;
    }
  }
}
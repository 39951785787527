/*
 * Core: General Layout Style
 * -------------------------
 */
html,
body,
.wrapper {
  min-height: 100%;
  overflow-x: hidden;
}

.wrapper {
  overflow: hidden;
  position: relative;

  & .content-wrapper {
    min-height: calc(100vh - 112px);
  }

  .layout-boxed & {
    @include box-shadow(0 0 10px rgba(0, 0, 0, .3));

    &,
    &:before {
      margin: 0 auto;
      max-width: $boxed-layout-max-width;
    }

    & .main-sidebar {
      left: inherit;
    }
  }

  .layout-navbar-fixed & {
    .content-wrapper {
      margin-top: 57px;
    }

    .main-header {
      position: fixed;
      z-index: $zindex-main-sidebar - 1;
      top: 0;
      right: 0;
      left: 0;
    }
  }

  .layout-footer-fixed & {
    .main-footer {
      position: fixed;
      z-index: $zindex-main-sidebar - 1;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }


  .layout-top-nav & {
    margin-left: 0;


    & .main-sidebar {
      bottom: inherit;
      height: inherit;
    }

    .brand-image {
      height: 33px;
    }

    & .main-sidebar{
      display: none;
    }

    & .content-wrapper,
    & .main-header,
    & .main-footer {
      margin-left: 0;
    }
  }
}

.content-wrapper,
.main-footer,
.main-header {
  @include media-breakpoint-up(sm) {
    @include transition(margin-left $transition-speed $transition-fn);

    margin-left: $sidebar-width;
    z-index: 3000;

    .sidebar-collapse & {
      margin-left: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    &,
    &:before {
      margin-left: 0;
    }
  }
}

.content-wrapper {
  background: $main-bg;

  > .content {
    padding: $content-padding-y $content-padding-x;
  }
}

.main-sidebar {
  &,
  &:before {
    $local-sidebar-transition: margin-left $transition-speed $transition-fn, width $transition-speed $transition-fn;
    @include transition($local-sidebar-transition);
    width: $sidebar-width;
  }

  .sidebar-collapse & {
    &,
    &:before {
      margin-left: -$sidebar-width;
    }
  }

  @include media-breakpoint-down(sm) {
    &,
    &:before {
      box-shadow: none!important;
      margin-left: -$sidebar-width;
    }

    .sidebar-open & {
      &,
      &:before {
        margin-left: 0;
      }
    }
  }
}

:not(.layout-fixed) {
  .main-sidebar {
    position: absolute;
    top: 0;
    height: inherit;
    min-height: 100%;
  }
}

.layout-navbar-fixed {

  &.sidebar-collapse {
    .brand-link {
      transition: width $transition-speed $transition-fn;
      width: $sidebar-mini-width;
      height: 57px;
    }

    .main-sidebar:hover {
      .brand-link {
        transition: width $transition-speed $transition-fn;
        width: $sidebar-width;
      }
    }
  }


  .sidebar {
    margin-top: 57px;
  }

  .brand-link {
    transition: width $transition-speed $transition-fn;
    position: fixed;
    overflow: hidden;
    top: 0;
    z-index: $zindex-main-sidebar + 1;
    width: $sidebar-width;
  }

  // Sidebar variants
  @each $name, $color in $theme-colors {
    .sidebar-dark-#{$name} .brand-link {
      background-color: $sidebar-dark-bg;
    }

    .sidebar-light-#{$name} .brand-link {
      background-color: $sidebar-light-bg;
    }
  }
}

.layout-fixed {
  .main-sidebar {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    float: none;
  } 
}


.main-footer {
  padding: $main-footer-padding;
  color: #555;
  border-top: $main-footer-border-top;
  background: $main-footer-bg;
}

.content-header {
  padding: 15px $content-padding-x;

  h1 {
    font-size: 1.8rem;
    margin: 0;
  }
  .breadcrumb {
    margin-bottom: 0;
    padding: 0;
    background: transparent;
    line-height: 1.8rem;
  }
}

.hold-transition {
  .content-wrapper,
  .main-header,
  .main-footer {
    transition: none!important;
  }
}

/*
 * Component: Button
 * -----------------
 */

.btn {
  // Flat buttons
  &.btn-flat {
    @include border-radius(0);
    box-shadow: none;
    border-width: 1px;
  }

  // input file btn
  &.btn-file {
    position: relative;
    overflow: hidden;
    > input[type='file'] {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
      font-size: 100px;
      text-align: right;
      opacity: 0;
      outline: none;
      background: white;
      cursor: inherit;
      display: block;
    }
  }
}

// Button color variations
.btn-default {
  background-color: $button-default-background-color;
  color: $button-default-color;
  border-color: $button-default-border-color;
  &:hover,
  &:active,
  &.hover {
    color: darken($button-default-color, 10%);
    background-color: darken($button-default-background-color, 5%);
  }
}

// Application buttons
.btn-app {
  @include border-radius(3px);
  position: relative;
  padding: 15px 5px;
  margin: 0 0 10px 10px;
  min-width: 80px;
  height: 60px;
  text-align: center;
  color: #666;
  border: 1px solid $button-default-border-color;
  background-color: $button-default-background-color;
  font-size: 12px;
  // Icons within the btn
  > .fa,
  > .fas,
  > .far,
  > .fab,
  > .glyphicon,
  > .ion {
    font-size: 20px;
    display: block;
  }

  &:hover {
    background: $button-default-background-color;
    color: $button-default-color;
    border-color: darken($button-default-border-color, 20%);
  }

  &:active,
  &:focus {
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
  }

  // The badge
  > .badge {
    position: absolute;
    top: -3px;
    right: -10px;
    font-size: 10px;
    font-weight: 400;
  }
}

